export const getLocalStorage = (type) => {
    const storage = localStorage.getItem(type)
    const array = storage ? JSON.parse(storage) : []
    return array
}

export const setLocalStorage = (type, array) => {
    array && localStorage.setItem(type, JSON.stringify(array))
}

export const getNum = (type) => {
    const array = getLocalStorage(type)
    return array ? array.length : 0
}

export const isInCart = (id) => {
    const array = getLocalStorage('cart')
    const bool = array && array.some(i => i.id === id && (i.checked === false || i.checked === 'false'))
        ? true
        : false
    return bool
}

export const isInList = (id) => {
    const array = getLocalStorage('list')
    return array && array.some(i => i.id === id)
        ? true
        : false
}

export const addItem = (type, id, setInCart = () => { }) => {
    const array = getLocalStorage(type)
    const filteredArray = array.filter(i => i.id !== id)
    if (type === 'dvds') {
        filteredArray.push(id)
    } else {
        filteredArray.push({ id: id, checked: false })
        setInCart(true)
    }
    setLocalStorage(type, filteredArray)
    window.dispatchEvent(new Event('list'))

}

export const updateItem = (type, id) => {
    const array = getLocalStorage(type)
    const filteredArray = array && array.filter(i => i.id !== id)
    const foundItem = array.find(i => i.id === id)
    const updatedItem = {
        id: id,
        checked: foundItem ? !foundItem.checked : false
    }
    const updatedArray = filteredArray.concat(updatedItem)
    setLocalStorage(type, updatedArray)
}

export const moveItem = (type, id) => {
    const array = getLocalStorage(type)
    const filteredArray = array.items.filter(i => i.id !== id)
    const updatedItem = { id: id, checked: true }
    const updatedArray = filteredArray.concat(updatedItem)
    setLocalStorage(type, updatedArray)
}

export const removeItem = (type, id, refreshData = () => { }) => {
    const array = getLocalStorage(type)
    let updatedArray
    if (type === 'dvds') {
        updatedArray = array.filter(i => i !== id)
    } else {
        updatedArray = array.filter(i => i.id !== id)
    }
    setLocalStorage(type, updatedArray)
    refreshData()
    window.dispatchEvent(new Event('list'))
}

export const keepCheckedItems = (type) => {
    const array = getLocalStorage(type)
    const updatedArray = array.filter(i => i.checked === true)
    setLocalStorage(type, updatedArray)
}

export const removeAllItems = (type) => {
    setLocalStorage(type, [])
}

export const getOrder = (type) => {
    sessionStorage.getItem(type)
}