import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { GiClick } from "react-icons/gi"
import isEmail from 'validator/lib/isEmail'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import DvdForm from '../comps/dvds/DvdForm'
import { SectionLoader } from '../comps/Loaders'
import DvdItemsList from '../comps/dvds/DvdItemsList'
import SelectVideos from '../comps/dvds/SelectVideos'
import { shippingAmount } from '../comps/dvds/shippingAmount'
import DvdTotalAndOrderNum from '../comps/dvds/DvdTotalAndOrderNum'
import MetaDecorator from '../helmet/MetaDecorator'
import { placeOrder } from '../utils/sql/placeOrder'
import { fetcher } from '../utils/functions/fetcher'
import { generateOrderNumber } from '../utils/functions/generateOrderNumber'
import { getLocalStorage, removeAllItems } from '../utils/localStorage/functions'
import '../styles/cart.css'
import { handleError } from '../comps/toast'
import { debitBalance } from '../comps/payments/PowerCoinzFunctions'

const coinUrl = 'https://data.powershotz.com/php/ga.php'
const dvdUrl = 'https://data.powershotz.com/php/gdd.php'

const Dvds = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const fixedOrderNum = localStorage.getItem('dvdOrderNumber')
    if (!fixedOrderNum) localStorage.setItem('dvdOrderNumber', generateOrderNumber())

    const [dvds, setDvds] = useState(getLocalStorage('dvds'))
    const [total, setTotal] = useState(0)
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [street, setStreet] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')
    const [comment, setComment] = useState('')
    const [coin, setCoin] = useState(null)
    const [coinPrice, setCoinPrice] = useState(0)
    const [txId, setTxId] = useState('')
    const [error, setError] = useState('')

    const shipping = dvds.length > shippingAmount
        ? dvds.length
        : shippingAmount

    const { data: allCoins, error: coinError, isLoading: coinIsLoading } = useSWR(coinUrl, fetcher)

    const { data: dvdData, error: dvdError, isLoading: dvdIsLoading } = useSWR(dvdUrl, fetcher)

    useEffect(() => {
        const body = document.getElementById('root')
        body.scrollIntoView(true)
    }, [])

    useEffect(() => { setTxId('') }, [coin])

    useEffect(() => {
        const handleSetTotal = () => {
            setTotal(0)
            dvds && dvds.map((item) => {
                let product = dvdData.find(i => i.value === item)
                return setTotal(prev => prev + parseFloat(product?.price))
            })
        }
        dvdData && handleSetTotal()
    }, [dvds, dvdData])

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem('dvdOrder'))
        if (data) {
            setEmail(data.email ? data.email : '')
            setName(data.customer_name ? data.customer_name : '')
            setStreet(data.address1 ? data.address1 : '')
            const split = data.address2
                ? data.address2.split(', ')
                : null
            setCity(split ? split[0] : '')
            const split2 = split ? split[1].split(' ') : null
            setState(split2 ? split2[0] : '')
            setZip(split2 ? split2[1] : '')
            setComment(data.comment ? data.comment : '')
        }
    }, [])

    const coins = allCoins

    const clearDvds = () => {
        sessionStorage.removeItem('dvdOrder')
        removeAllItems('dvds')
    }

    const handleGoToCheckout = () => {
        const body = document.getElementById('checkout')
        body.scrollIntoView(true)
    }

    const runPlaceOrder = async (data) => {
        placeOrder(data)
            .then(() => clearDvds())
            .then(() => localStorage.removeItem('dvdOrderNumber'))
            .then(() => navigate(`/order/${fixedOrderNum}`))
            .catch(e => console.log(e.message))
    }

    const saveOrder = async () => {
        if (!isEmail(email)) {
            handleError(t('errors.email'))
            setError(t('errors.email'))
            const body = document.getElementById('dvdForm')
            body.scrollIntoView(true)
        } else if (!coin || coin === '') {
            handleError(t('errors.choose_payment'))
            setError(t('errors.choose_payment'))
            const body = document.getElementById('payment')
            body.scrollIntoView(true)
        } else if (coin.name === 'coinz' && !coin.balance) {
            setError(t('coinz.must_login'))
            handleError(t('coinz.must_login'))
        } else if (coin.name === 'coinz' && coin.balance < total) {
            setError(t('coinz.not_enough'))
            handleError(t('coinz.not_enough'))
        } else {
            const data = {
                orderNumber: fixedOrderNum,
                total: total + shipping,
                coinPrice: coinPrice,
                coin: coin,
                paymentId: txId,
                email: email,
                comment: comment,
                order_type: 'dvd',
                customer_name: name,
                address1: street,
                address2: `${city}, ${state} ${zip}`,
                items: dvds,
                numOfItems: dvds.length,
            }
            sessionStorage.setItem('dvdOrder', JSON.stringify(data))
            debitBalance(data)
            runPlaceOrder(data)
        }
    }

    if (coinError || dvdError) return <h1 style={styles.error}>{t('main.error')}</h1>
    if (
        coinIsLoading ||
        dvdIsLoading ||
        !allCoins ||
        !dvdData
    ) return <div style={styles.error}><SectionLoader /></div>

    return (
        <div className={`cart_main`}>

            <MetaDecorator
                title="DVD Order Form"
                description="Order Shippable Powershotz.com DVDs"
            />

            <div
                className={`cart_heading`}
                style={styles.headingAndButton}
            >
                <h1 style={styles.heading}>
                    {t('dvd.order_form')}
                </h1>

                {dvds && dvds.length > 0 &&
                    <button onClick={handleGoToCheckout}>
                        {t('cart.checkout')}
                    </button>
                }
            </div>

            <p className={`usShippingOnly`}>{t('dvd.us_only')}</p>

            <p>
                <a href='/cart' className={`cart_otherForm`}>
                    {t('cart.cart_link')}
                </a>
            </p>

            {!dvds || dvds.length === 0
                ? <div style={{ textAlign: 'left' }}>
                    <SelectVideos
                        dvds={dvds}
                        setDvds={setDvds}
                        dvdData={dvdData}
                    />
                </div>
                : <>
                    <DvdTotalAndOrderNum
                        total={total}
                        setTotal={setTotal}
                        orderNumber={fixedOrderNum}
                        numberOfDvds={dvds.length}
                    />

                    <SelectVideos
                        dvds={dvds}
                        setDvds={setDvds}
                        dvdData={dvdData}
                    />

                    <DvdItemsList
                        dvds={dvds}
                        setDvds={setDvds}
                    />

                    <div
                        id='checkout'
                        className={`paddingForCheckoutDvd`} />

                    <div style={styles.headingAndSmallButton}>
                        <h1 className={`checkoutFormHeadingDvd`}>{t('cart.checkout_form')}</h1>

                        <button
                            title={`${t('cart.place_order')}\n${t('payment.click').toUpperCase()}`} onClick={saveOrder}
                            style={styles.smallButton}
                        >
                            {t('cart.place_order')}
                            <GiClick style={styles.clickIcon} />
                        </button>
                    </div>

                    <DvdForm
                        email={email}
                        setEmail={setEmail}
                        name={name}
                        setName={setName}
                        street={street}
                        setStreet={setStreet}
                        city={city}
                        setCity={setCity}
                        state={state}
                        setState={setState}
                        zip={zip}
                        setZip={setZip}
                        comment={comment}
                        setComment={setComment}
                        setError={setError}
                        coin={coin}
                        setCoin={setCoin}
                        coins={coins}
                        txId={txId}
                        setTxId={setTxId}
                        coinPrice={coinPrice}
                        setCoinPrice={setCoinPrice}
                        total={total + shipping}
                        fixedOrderNum={fixedOrderNum}
                        order_type='dvd'
                        saveOrder={saveOrder}
                        orderNumber={fixedOrderNum}
                        error={error}
                    />

                    <div style={styles.buttonAndTerm}>
                        <button
                            title={`${t('cart.place_order')}\n${t('payment.click').toUpperCase()}`} onClick={saveOrder}
                            style={styles.bigButton}
                        >
                            {t('cart.place_order')}
                            <GiClick style={styles.clickIcon} />
                        </button>

                        <p style={styles.bigText}
                        >{t('payment.click')}</p>
                    </div>
                </>
            }
        </div>
    )
}

const styles = {
    headingAndButton: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    heading: {
        margin: 0,
    },
    usa: {

    },
    error: {
        textAlign: 'center',
        textTransform: 'uppercase',
        marginTop: 200,
        marginBottom: 100,
    },
    bigButton: {
        width: '75%',
        height: 55,
        fontSize: isMobile ? '1.1rem' : '1.5rem',
    },
    headingAndSmallButton: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    smallButton: {
        fontSize: '1.1rem',
        marginLeft: 0,
    },
    smallText: {
        fontSize: '0.8rem',
        textAlign: 'left',
        width: '80%',
    },
    bigText: {
        margin: 0,
    },
    buttonAndTerm: {
        textAlign: 'center',
    }
}

export default Dvds