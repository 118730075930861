import { getImageLink } from './getImageLink'
import { getMedia } from './getMedia'
import { getGifData } from '../../../utils/sql/getGifData'
import { findProduct } from '../../../utils/sql/findProduct'
import { getModelData } from '../../../utils/sql/getModelData'
import { getStarsInData } from '../../../utils/sql/getStarsInData'

export const setProductData = async (pathname, setProduct, setMediaList, setImageLink, setStarsInData) => {
    if (!pathname) return

    let path = pathname.replace('/', '')

    let product, mediaList, imageLink, starsInData = null

    product = await findProduct(path) //post
    const gifData = await getGifData(path) //post
    const modelData = await getModelData(path) //post
    const productId = product.id ?? ''
    starsInData = await getStarsInData(path, productId) //post
    imageLink = getImageLink(product)
    mediaList = getMedia(product, imageLink, gifData, modelData)

    setProduct(product)
    setMediaList(mediaList)
    setImageLink(imageLink)
    setStarsInData(starsInData)
}