import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { handleError, handleGenToast } from '../toast'
import '../../styles/cart.css'
import { isBanned } from './banned'

const EnterEmail = (props) => {
    const { t } = useTranslation()
    const { orderNumber, setIsVerified, highlightInput, setHighlightInput } = props

    const [email, setEmail] = useState('')
    const [message, setMessage] = useState(t('order.enter_email_access'))

    const handleSubmit = async (e) => {
        e.preventDefault()

        const url = `https://data.powershotz.com/php/ve.php?on=${orderNumber}`

        fetch(url)
            .then(res => res.json())
            .then(data => {
                return data.email.toLowerCase().trim()
            })
            .then((_orderEmail) => {
                if (_orderEmail === email.toLowerCase().trim()) {
                    setIsVerified(true)
                    setMessage(t('main.success'))
                    sessionStorage.setItem('email', email)
                    handleGenToast(t('main.logged_in'))
                } else {
                    setIsVerified(false)
                    setMessage(t('main.invalid_email'))
                    sessionStorage.removeItem('email')
                    handleError(t('main.invalid_email'))
                }
            })
            .catch(e => console.error(e))
    }

    const handleEmail = async (email) => {
        setEmail(email)
        await isBanned(email, setEmail)
        setHighlightInput(false)
        setMessage(t('order.enter_email_access'))
    }

    return (
        <div>
            <form
                onSubmit={handleSubmit}
                style={styles.form}
            >
                <input
                    onChange={(e) => handleEmail(e.target.value)}
                    name='email'
                    type='email'
                    className={highlightInput
                        ? 'cart_inputContact_highlight'
                        : `cart_inputContact`}
                    value={email}
                    placeholder={message}
                    style={styles.input}
                    title={t('order.enter_email_access')}
                />

                <div style={styles.button}>
                    <button type='submit'>{t('main.login')}</button>
                </div>
            </form>
        </div>

    )
}

const styles = {
    form: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'center' : 'flex-start',
        justifyContent: 'center',
        marginBottom: 20,
        marginTop: 20,
    },
    input: {
        marginLeft: isMobile ? 0 : 10,
        fontSize: isMobile ? '1rem' : '1.1rem',
        paddingLeft: 10,
    },
}

export default EnterEmail