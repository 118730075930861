import { useEffect, useState, useRef } from 'react'
import useSWR from 'swr'
import { GiClick } from "react-icons/gi"
import isEmail from 'validator/lib/isEmail'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { handleError, handleThankYou } from '../comps/toast'
import CartForm from '../comps/cart/CartForm'
import { SectionLoader } from '../comps/Loaders'
import { runPlaceOrder } from '../comps/cart/runPlaceOrder'
import TrackerNotification from '../comps/trackerNotification'
import ReviewAndPlaceOrder from '../comps/cart/ReviewAndPlaceOrder'
import { fetcher } from '../utils/functions/fetcher'
import { findProduct } from '../utils/sql/findProduct'
import { getLocalStorage } from '../utils/localStorage/functions'
import { generateOrderNumber } from '../utils/functions/generateOrderNumber'

const url = 'https://data.powershotz.com/php/ga.php'

const Checkout = () => {
    const { t } = useTranslation()
    const emailRef = useRef()
    const paymentRef = useRef()

    const storedOrderNumber = localStorage.getItem('cartOrderNumber')
    if (!storedOrderNumber) localStorage.setItem('cartOrderNumber', generateOrderNumber())

    const { data: coins, error: coinError, isLoading } = useSWR(url, fetcher)

    const [total, setTotal] = useState(0)
    const [comment, setComment] = useState('')
    const [email, setEmail] = useState('')
    const [coin, setCoin] = useState(null)
    const [coinPrice, setCoinPrice] = useState(0)
    const [txId, setTxId] = useState('')
    const [error, setError] = useState('')
    const [cart, setCart] = useState([])
    const [action, setAction] = useState(true)
    const [num, setNum] = useState(0)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const fetchCartData = () => setCart(getLocalStorage('cart'))

    useEffect(() => {
        if (action) {
            fetchCartData()
            setAction(false)
        }
    }, [action])

    useEffect(() => { setTxId('') }, [coin])

    useEffect(() => {
        const body = document.getElementById('root')
        body.scrollIntoView(true)
    }, [])

    useEffect(() => {
        const adjustWindowWidth = () => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', adjustWindowWidth, true)
        return () => window.removeEventListener('resize', adjustWindowWidth)
    }, [])

    useEffect(() => {
        setTotal(0)
        cart &&
            cart.map(async (item) => {
                let product = await findProduct(item.id)
                product && setTotal((prev) => prev + parseFloat(product.price))
            })
        setNum(cart.length)
    }, [cart])

    const saveOrder = () => {
        const data = {
            orderNumber: storedOrderNumber,
            total: total,
            coinPrice: coin.name === 'gumroad' ||
                coin.name === 'coinz' ||
                coin.name === 'zelle' ||
                coin.name === 'cash-app'
                ? total
                : coinPrice,
            coin: coin,
            coinName: coin.name,
            paymentId: txId,
            email: email,
            comment: comment,
            items: cart.map((i) => i.id),
            order_type: 'download',
            numOfItems: num,
            customer_name: '',
            address1: '',
            address2: '',
        }

        sessionStorage.setItem('order', JSON.stringify(data))
        if (!email || email === '') return
        handleThankYou()
        runPlaceOrder(data)
    }

    const checkCoinz = () => {
        if (coin.name === 'coinz' && !coin.balance) {
            return false
        } else if (coin.name === 'coinz' && coin.balance < total) {
            return false
        } else {
            return true
        }
    }

    const handleClick = () => {
        if (
            isEmail(email) &&
            email !== '' &&
            coin &&
            coin !== 'none' &&
            total !== 0 &&
            checkCoinz()
        ) {
            setError('')
            saveOrder()
        } else if (!isEmail(email)) {
            setError(t('errors.email'))
            window.scrollTo(0, emailRef.current.offsetTop)
            handleError(t('errors.email'))
        } else if (!coin || coin === 'none') {
            setError(t('errors.choose_payment'))
            window.scrollTo(0, paymentRef.current.offsetTop)
            handleError(t('errors.choose_payment'))
        } else if (coin.name === 'coinz' && !coin.balance) {
            setError(t('coinz.must_login'))
            window.scrollTo(0, paymentRef.current.offsetTop)
            handleError(t('coinz.must_login'))
        } else if (coin.name === 'coinz' && coin.balance < total) {
            setError(t('coinz.not_enough'))
            window.scrollTo(0, paymentRef.current.offsetTop)
            handleError(t('coinz.not_enough'))
        } else {
            setError(t('cart.empty'))
            handleError(t('cart.empty'))
        }
    }

    if (coinError) return <div>{t('main.error')}</div>
    if (isLoading) return <div><SectionLoader /></div>
    if (!coins) return null

    return (
        <div style={styles.main}>
            <div style={styles.titleAndButton}>
                <h1 style={styles.heading}>
                    {t('cart.checkout_form')}
                </h1>

                {windowWidth <= 1600 &&
                    <button
                        title={`${t('cart.place_order')}\n${t('payment.click').toUpperCase()}`}
                        onClick={handleClick}
                    >
                        {t('cart.place_order')}
                        <GiClick style={styles.clickIcon} />
                    </button>
                }
            </div>

            <TrackerNotification />

            {windowWidth > 1600 &&
                <div style={styles.smallReview}>
                    <ReviewAndPlaceOrder
                        orderNumber={storedOrderNumber}
                        handleClick={handleClick}
                        small={true}
                    />
                </div>
            }

            <CartForm
                email={email}
                setEmail={setEmail}
                comment={comment}
                setComment={setComment}
                setError={setError}
                coin={coin}
                setCoin={setCoin}
                coins={coins}
                txId={txId}
                setTxId={setTxId}
                coinPrice={coinPrice}
                setCoinPrice={setCoinPrice}
                total={total}
                fixedOrderNum={storedOrderNumber}
                saveOrder={saveOrder}
                error={error}
                emailRef={emailRef}
                paymentRef={paymentRef}
            />

            <ReviewAndPlaceOrder
                // orderNumber={storedOrderNumber}
                handleClick={handleClick}
                small={false}
            />
        </div >
    )
}

const styles = {
    main: {
        margin: 'auto',
        maxWidth: 1000,
        minHeight: '100vh',
        width: '90%',
        marginBottom: 100,
    },
    heading: {
        margin: isMobile ? 0 : 10,
    },
    titleAndButton: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    buttonAndTerm: {
        textAlign: 'center'
    },
    bigButton: {
        width: '75%',
        height: 55,
        fontSize: '1.5rem',
    },
    clickIcon: {
        marginLeft: 10,
    },
    smallReview: {
        width: 300,
        position: 'fixed',
        top: 75,
        right: 0
    }
}

export default Checkout